<template>
    <el-container class="page-container">
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-date-picker
                                v-model="query.time"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                                style="width: auto"
                        >
                        </el-date-picker>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

<!--            <el-row class="row-btn-group">-->
<!--                <el-button type="primary" @click="handleAdd('新增')">-->
<!--                    <i class="cw-icon cw-icon-tianjia"></i>新增-->
<!--                </el-button>-->
<!--            </el-row>-->
            <el-table
                    :data="posData"
                    style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-table :data="props.row.foodShoppingList">
                            <el-table-column
                                    label="物料名称"
                                    prop="materialName">
                            </el-table-column>
                            <el-table-column
                                    label="物料ID"
                                    prop="material_id">
                            </el-table-column>
                            <el-table-column
                                    label="数量"
                                    prop="num">
                            </el-table-column>
                            <el-table-column
                                    label="物料单位"
                                    prop="unit">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column
                        label="订单号"
                        prop="id"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        label="备注"
                        prop="note"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        label="创建时间"
                        prop="createTime"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <!--<el-table-column-->
                        <!--label="修改时间"-->
                        <!--prop="updateTime"-->
                        <!--:show-overflow-tooltip="true"-->
                <!--&gt;-->
                <!--</el-table-column>-->
                <!--<el-table-column-->
                        <!--label="操作人"-->
                        <!--prop="updateUser">-->
                <!--</el-table-column>-->
                <el-table-column
                        label="操作"
                >
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="handleAddNote(scope.row)" plain>备注</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <el-dialog title="新增订单" :visible.sync="visibleFormDialog" :fullscreen="true">
            <el-table
                    ref="multipleTable"
                    :data="setArr"
                    tooltip-effect="dark"
                    style="width: 100%"
            >
                <el-table-column
                        label="物料类型"
                        prop="categoryName"
                        show-overflow-tooltip
                >

                </el-table-column>
                <el-table-column
                        label="名称"
                        prop="name"
                        show-overflow-tooltip
                >

                </el-table-column>

                <el-table-column
                        prop="num"
                        label="数量"
                        show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-input maxlength="100" placeholder="数量"
                                  v-model="scope.row.num"
                                  min="0"
                                  auto-complete="off" tabindex="5" type="number"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="unit"
                        label="单位"
                        show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <div class="flex flex-direction-row-reverse btn-group" style="margin-top: 20px">
                <el-button type="primary" @click="doAdd">确 定</el-button>
                <el-button @click="closeModul">取 消</el-button>
            </div>
        </el-dialog>
        <el-dialog title="修改订单" :visible.sync="visibleEditDialog" :fullscreen="true">
            <el-table
                    ref="multipleTable"
                    :data="formData.foodShoppingList"
                    tooltip-effect="dark"
                    style="width: 100%"
            >
                <el-table-column
                        label="物料类型"
                        prop="categoryName"
                        show-overflow-tooltip
                >

                </el-table-column>
                <el-table-column
                        label="名称"
                        prop="materialName"
                        show-overflow-tooltip
                >

                </el-table-column>

                <el-table-column
                        prop="num"
                        label="数量"
                        show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-input maxlength="100" placeholder="数量"
                                  v-model="scope.row.num"
                                  min="0"
                                  auto-complete="off" tabindex="5" type="number"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="unit"
                        label="单位"
                        show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <div class="flex flex-direction-row-reverse btn-group" style="margin-top: 20px">
                <el-button type="primary" @click="doEdit">确 定</el-button>
                <el-button @click="closeModul">取 消</el-button>
            </div>
        </el-dialog>
    </el-container>

</template>

<script>
  import {mapGetters} from 'vuex'
  import formDialog from '../../components/formDialog'
  import Upload from '../../components/upload/index'
  import {
    selectFoodShoppingList,
    getFoodMaterialList,
    addFoodShoppingList,
    updateFoodShoppingList,noteDo
  } from '../../api/foodManager'
  import moment from 'moment'

  const switchTypes = [{label: '小程序子页面', value: 1}, {label: '小程序导航', value: 2}, {label: 'webview活动页', value: 3}]
  export default {
    name: "companyManagement",
    components: {formDialog, Upload},
    data() {
      return {
        tableData: [],
        pageInfo: {
          totalCount: 0,
          pageNumber: 1,
          pageSize: 10,
          pageOrderName: 'opAt',
          pageOrderBy: 'descending'
        },
        query: {
          time:null
        },
        visibleFormDialog: false,
        visibleEditDialog:false,
        formData: {
          foodShoppingList:[],
        },
        editTitle: '',
        formRules: {

        },
        selectData: [],
        posData: [],
        setArr:[],
        switchTypes,
      }
    },
    computed: {
      ...mapGetters([
        'userInfo'
      ]),

    },
    methods: {
      formatSwitchType(row, column, cellValue, index) {
        let {switchType} = row
        let status = switchTypes.find(i => i.value === switchType)
        return status ? status.label : '--'
      },
      handleSearch() {
        this.pageInfo.pageNumber = 1;
        this.selectFoodShoppingList()
      },
      clearSearch() {
        this.query = {
          name: null,
        },
          this.handleSearch()
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val
        this.pageInfo.page = 1
        this.selectFoodShoppingList()
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.pageInfo.pageNumber = val
        this.selectFoodShoppingList()
      },
      handleAdd() {
        this.selectMaterialCategoryList();
        this.visibleFormDialog = true;
      },
      //修改
      // handleEdit(row) {
      //   this.formData = row;
      //   this.visibleEditDialog = true
      // },
        handleAddNote(row){
            this.$prompt('请输入备注', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\S/,
                inputErrorMessage: '请输入内容'
            }).then(async({ value }) => {
                try {
                    let formData ={
                        id:row.id,
                        note:value
                    }
                    await noteDo(formData)
                    this.$message({
                        type: 'success',
                        message: '添加成功'
                    });
                    this.handleSearch()
                }catch (e) {

                }
                console.log(value)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },


      async doAdd() {
        try {
          let formData=this.setArr.filter(curr=>curr.num);
          if(formData.length>0){
            await addFoodShoppingList({foodShoppingList:JSON.stringify(formData)});
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
            this.selectFoodShoppingList();
          }
          this.visibleFormDialog = false;
        }catch (e) {

        }
      },
      async doEdit(){
        try {
          this.formData.foodShoppingList.forEach(curr=>{
            curr.group_flag=this.formData.id;
          })
          let formData={foodShoppingList:JSON.stringify(this.formData.foodShoppingList)};
          await updateFoodShoppingList(formData);
          this.visibleEditDialog=false;
          this.selectFoodShoppingList();
        }catch (e) {

        }
      },
      //删除
      handleSelectionChange(val) {
        console.log(334, val);
        this.selectData = val;
      },
      closeModul() {
        this.visibleFormDialog = false;
        this.visibleEditDialog=false
        this.formData = {};
        this.selectFoodShoppingList();
      },
      async doDelMore() {
        let ids = [];
        this.selectData.forEach(function (val) {
          ids.push(val.id);
        });
        this.$confirm('删除后无法恢复，确定删除选中选项吗?', '确定', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            await advertiseDeleteMore({ids: ids.toString()});
            this.$message({
              type: 'success',
              message: '更新成功!'
            });
            this.selectFoodShoppingList()
          } catch (e) {
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      },
      async selectFoodShoppingList() {
        let obj=!this.query.time?{}:{startTime:new Date(this.query.time[0]+' 00:00:00').getTime()/1000,endTime:new Date(this.query.time[1]+' 23:59:59').getTime()/1000}
        let query = Object.assign({}, this.pageInfo,obj)
        let formData = {}
        for (let i in query) {
          if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
            formData[i] = query[i]
          }
        }
        let {data} = await selectFoodShoppingList(formData);
        console.log(363,data);
        this.pageInfo.totalCount=data.totalCount;
        this.posData = data.list;
      },
      async selectMaterialCategoryList() {
        let {data} = await getFoodMaterialList();
        data.goodsMaterialList.forEach(curr=>{
          curr.num='';
        })
        // console.log(533,data.goodsMaterialList);
        this.setArr=data.goodsMaterialList;
      },
    },
    mounted() {
      this.selectFoodShoppingList();
      this.selectMaterialCategoryList();
    }
  }
</script>

<style lang="less">
    .adsdia {
        .el-upload {
            height: 100px;
            width: 100px !important;
            line-height: 100px;
        }
    }

    .btn-group {
        button {
            margin-left: 20px;
        }

    }

    .el-input--prefix .el-input__inner {
        padding-left: 46px;
    }

    .el-input__prefix {
        left: 15px;
        color: #303133;
        i {
            font-style: normal;
        }
    }

    .icon-cursor {
        cursor: pointer;
        margin-right: 10px;
        &:hover {
            color: #348EED;
        }
    }

    .avatar-uploader {
        .el-upload {
            border: 1px solid #DCDFE6;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            width: 72px;
            display: block;
            &:hover {
                border-color: #409EFF;
            }
        }
        .cw-icon-tupian {
            font-size: 24px;
            color: #c0c4cc;
            width: 72px;
            height: 72px;
            line-height: 72px;
            text-align: center;
        }
        .avatar {
            width: 72px;
            height: 72px;
            display: block;
        }
    }
</style>
